

















import { User } from "@/models/user.model";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({ components: {} })
export default class UserListFilter extends Vue {
  loading = false;

  @Prop()
  filter!: any;
}
