



















import { User } from "@/models/user.model";
import { PaginatedResult } from "@/shared/types/paginated-result.class";
import Vue from "vue";
import Component from "vue-class-component";
import { namespace } from "vuex-class";
import UserListCard from "../components/users/UserListCard.vue";
import UserListFilter from "../components/users/UserListFilter.vue";

const usersNamespace = namespace("Users");
const featuresNamespace = namespace("Features");
@Component({
  components: {
    UserListCard,
    UserListFilter,
  },
})
export default class UserList extends Vue {
  isLoading = false;
  userFilter = {};

  @featuresNamespace.Getter("whoIsWho")
  whoIsWhoEnabled!: boolean;

  @usersNamespace.Getter("all")
  paginatedUsers!: PaginatedResult<User>;

  @usersNamespace.Action("fetchPublic")
  fetchUsers!: (filter?: any) => Promise<PaginatedResult<User>>;

  async doFetch() {
    this.isLoading = true;
    await this.fetchUsers();
    this.isLoading = false;
  }

  created() {
    this.doFetch();
  }
}
