



















































import { User } from "@/models/user.model";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({ components: {} })
export default class UserListCard extends Vue {
  loading = false;

  @Prop()
  user!: User;

  @Prop({ default: false })
  showCompletion!: boolean;

  get name() {
    return `${this.user?.first_name} ${this.user?.last_name}`;
  }
  get job() {
    return this.user?.job || "job";
  }

  get userInitials() {
    return `${this.user?.first_name?.charAt(0)}${this.user?.last_name?.charAt(
      0
    )}`;
  }
  get userId() {
    return this.user?.id;
  }

  get profilePicture() {
    return this.user?.profile_image_url;
  }
}
